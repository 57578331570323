import SubPageLayout from '../../../ui/layout/SubPageLayout';
import SoulhouseLocations from '../Locations/SoulhouseLocations';
import { useContextTranslation } from '../../../ui/translation';
import { useLocations } from '../Locations/useLocations';
import { useRef, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSpinner,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import styles from './assistedStertch.module.scss';
import { useTreatmentBuilder } from '../../state/TreatmentContext';
import BeforeContentGallery from '../../../ui/elements/BeforeContentGallery';
import { defaultMedia, defaultMediaWeb } from './AssistedStretchSearch';

const RESULTS_PER_PAGE = 5;

const AssistedStretch = () => {
    const mt = useContextTranslation('misc');
    const t = useContextTranslation('page.assisted_stretch');
    const { value: locations, loading, fetchMore } = useLocations('stretch');
    const infiniteScroll = useRef<HTMLIonInfiniteScrollElement>(null);
    const wasTriggered = useRef<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const disabled = Boolean(
      locations && locations.length < page * RESULTS_PER_PAGE && !loading,
    );

    const {setVariant, setNumberOfExperts } = useTreatmentBuilder();

    useIonViewWillEnter(() => {
      setVariant('stretch');
      setNumberOfExperts(1);
    }, []);

    useIonViewDidEnter(() => {
      if (wasTriggered.current) {
        // eslint-disable-next-line no-console
        infiniteScroll.current?.complete().catch(console.error);
        wasTriggered.current = false;
      }
    }, [locations]);


    return (
      <SubPageLayout
        withBanner
        beforeContent={
          <BeforeContentGallery
            media={window.innerWidth > 1000 ? defaultMediaWeb : defaultMedia}
          />
        }
      >
        <IonGrid className={styles.grid}>
          <IonRow>
            <IonCol size="12">
              <div className={styles.descriptionCard}>
                <h3 className={styles.descriptionHeading}>{t('heading')}</h3>
                <p className={styles.descriptionContent}>{t('description')}</p>
              </div>
            </IonCol>
          </IonRow>
          {(loading || !locations) ? <IonSpinner color="secondary" /> : (
            <SoulhouseLocations locations={locations} />
          )}
          <div>
            <IonInfiniteScroll
              ref={infiniteScroll}
              threshold="10%"
              disabled={disabled}
              onIonInfinite={() => {
                fetchMore?.({
                  variables: {
                    pagination: {
                      offset: RESULTS_PER_PAGE * (page + 1),
                      limit: RESULTS_PER_PAGE,
                    },
                  },
                });
                wasTriggered.current = true;
                setPage((p: number) => p + 1);
              }}
            >
              <IonInfiniteScrollContent loadingText={mt('loading')} />
            </IonInfiniteScroll>
          </div>
        </IonGrid>
      </SubPageLayout>
    );
  }
;

export default AssistedStretch;