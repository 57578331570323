import { IonCol, IonSpinner } from '@ionic/react';
import { TreatmentVariant, useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { TreatmentTimeRange } from 'application/types';
import { addMonths, isSameMonth, isSameYear, startOfMonth } from 'date-fns';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useContextTranslation } from 'ui/translation';

import type { CurrentlyAvailableBookings } from '../ITreatmentAdapter';
import CalendarWithDynamicHighlights from './CalendarWithDynamicHighlights';
import { getRange } from './dateModalUtils';
import TimeRangeSelector from './TimeRangeSelector';
import { assistedStretchType } from '../../AssistedStretch/AssistedStretchAtoms';
import { useAtomValue } from 'jotai';
import { gql, useQuery } from '@apollo/client';

const currentDate = new Date();

const GET_AVAILABILITIES_IN_TIME_RANGE = gql`
    query GetAvailabilitiesInTimeRange(
        $postalCode: String
        $treatmentTypeId: ID
        $timeRange: TimeRange!
        $spaAvailabilities: Boolean
        $locationId: ID
        $treatmentTypeVariant: TreatmentTypeVariant
    ) {
        expertAvailabilitiesInRange(
            postalCode: $postalCode
            treatmentTypeId: $treatmentTypeId
            timeRange: $timeRange
            spaAvailabilities: $spaAvailabilities
            locationId: $locationId
            treatmentTypeVariant: $treatmentTypeVariant
        ) {
            totalExperts
            availableDays {
                date
                availableExperts {
                    morning
                    afternoon
                    evening
                }
                minimalTreatmentPrice {
                    morning
                    afternoon
                    evening
                }
            }
        }
    }
`;

const getVariant = (isSpaBooking: boolean, variant: TreatmentVariant) => {
  if (isSpaBooking) {
    return 'spa';
  }
  if (variant === 'stretch') {
    return 'stretch';
  }
  return 'normal';
};


const DynamicHighlightCalendarWrapper = ({
  date,
  setDate,
  timeRange,
  setTimeRange,
}: {
  date?: Date;
  timeRange?: TreatmentTimeRange;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  setTimeRange: Dispatch<SetStateAction<TreatmentTimeRange | undefined>>;
}): JSX.Element => {
  const t = useContextTranslation('page.treatment.date');
  const [calendarViewDate, setCalendarViewDate] = useState(date || currentDate);
  const calendarMonth = useRef(1)

  useEffect(() => {
    if (
      date &&
      (!isSameMonth(date, calendarViewDate) ||
        !isSameYear(date, calendarViewDate))
    ) {
      setCalendarViewDate(date);
    }
  }, [date]);

  const { location, locationId, isSpaBooking, variant } = useTreatmentBuilder();
  const { start, end } = getRange(calendarViewDate);
  const type = useAtomValue(assistedStretchType);

  const { data, loading } = useQuery<{
    expertAvailabilitiesInRange: CurrentlyAvailableBookings;
  }>(GET_AVAILABILITIES_IN_TIME_RANGE, {
    variables: {
      postalCode: location?.postalCode,
      timeRange: {
        fromDate: start,
        toDate: end,
      },
      locationId: locationId,
      spaAvailabilities: Boolean(isSpaBooking),
      treatmentTypeId: (variant === 'stretch' && type) ? type.id : null,
      treatmentTypeVariant: getVariant(Boolean(isSpaBooking), variant),
    },
    fetchPolicy: 'network-only',
  });

  const availableBookings = data?.expertAvailabilitiesInRange
    ? {
      availableDays:
        data.expertAvailabilitiesInRange.availableDays?.filter(
          (availableDay) => {
            const { availableExperts } = availableDay;
            return (
              availableExperts.morning ||
              availableExperts.afternoon ||
              availableExperts.evening
            );
          },
        ),
      totalExperts: data.expertAvailabilitiesInRange.totalExperts,
    }
    : null;

  if (!loading && availableBookings?.availableDays && calendarMonth.current < 2) {
    const nextFetchDate = addMonths(calendarViewDate, calendarMonth.current);
    calendarMonth.current = ++calendarMonth.current
    const nextDateRanges = startOfMonth(nextFetchDate);
    const isAnyAvailabilityInMonth = availableBookings.availableDays.reduce((acc, dayWithAvailability) => {
      if (dayWithAvailability.availableExperts.morning ||
        dayWithAvailability.availableExperts.afternoon ||
        dayWithAvailability.availableExperts.evening) {
        return true;
      }
      return acc

    }, false);

    if(!isAnyAvailabilityInMonth) {
      setCalendarViewDate(nextDateRanges)
    }
  }

  return (
    <>
      {loading || !availableBookings ? (
        <IonCol>
          <IonSpinner color="secondary" name="dots" />
        </IonCol>
      ) : (
        <>
          <IonCol size="12" className="calendar-wrapper">
            <h4 className="calendar-info">{t('available_days_info')}</h4>
            <CalendarWithDynamicHighlights
              date={date}
              setDate={setDate}
              setTimeRange={setTimeRange}
              calendarViewDate={calendarViewDate}
              setCalendarViewDate={setCalendarViewDate}
              availableBookings={availableBookings}
            />
          </IonCol>
          <IonCol size="12">
            <h4>{t('time_period_header')}</h4>
            <TimeRangeSelector
              date={date}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              availableBookings={availableBookings}
            />
          </IonCol>
        </>
      )}
    </>
  );
};

export default DynamicHighlightCalendarWrapper;
