import { TreatmentVariant } from 'application/state/TreatmentContext';
import type { ObjectID } from 'application/types';
import { TreatmentTimeRange } from 'application/types';
import type { TimeRange } from 'application/types/utils';
import {
  endOfDay,
  isSameMonth,
  isSameYear,
  lastDayOfMonth,
  startOfMonth,
} from 'date-fns';
import { useInject } from 'inversify-hooks';

import injectables from '../../injectables';
import type { ITreatmentAdapter } from '../ITreatmentAdapter';
import { useCallback } from 'react';

let fromDate = new Date();

export const getRange = (calendarViewDate: Date): TimeRange => {
  if (
    !isSameYear(calendarViewDate, fromDate) ||
    !isSameMonth(calendarViewDate, fromDate)
  ) {
    fromDate = startOfMonth(calendarViewDate);
  }
  return {
    start: fromDate,
    end: endOfDay(lastDayOfMonth(fromDate)),
  };
};

export const useMinimalPriceForExpert = (
  locationId: ObjectID | null,
  variant?: TreatmentVariant,
) => {
  const [service] = useInject<ITreatmentAdapter>(injectables.TreatmentAdapter);
  const treatmentVariant = variant === 'stretch' ? 'stretch' : null;

  const types = service.useTreatmentTypes({
    treatmentTypeVariant: treatmentVariant,
    locationId: locationId || null,
    ignoreOnlySpa: true,
  });

  return useCallback<
    (expertTypes: ObjectID[], range?: TreatmentTimeRange) => number
  >(
    (expertTypes, range) => {
      if (types.value) {
        const intersectionTypes = types.value.filter((el) =>
          expertTypes.includes(el.id),
        );

        return intersectionTypes
          .flatMap((tp) => tp.prices)
          .map((price) =>
            parseFloat(price[range || TreatmentTimeRange.Morning]),
          )
          .reduce<number>((min, val) => (!min || val < min ? val : min), 0);
      }
      return 0;
    },
    [types.value],
  );
};
